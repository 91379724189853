<template>
  <div class="d-flex flex-column h-100 mx-2">
    <div
      class="
        d-flex
        flex-column flex-md-row
        justify-content-between
        align-items-center
      "
    >
      <div class="d-flex mt-1 py-1 align-items-center">
        <icon-button
          id="detailsButton"
          icon="eye"
          label="View"
          type="primary"
          :disabled="!selectionExists"
          class="mr-2"
          data-cy="view"
          @click="openDetailModal"
        />

        <input-box
          id="startDate"
          v-model.lazy="startDate"
          class="my-0 mx-1"
          type="date"
          addon="start"
          aria-label="Start Date"
          data-cy="start-date"
        >
          <span slot="addon-start" class="input-group-text"> Start </span>
        </input-box>
        <input-box
          id="endDate"
          v-model.lazy="endDate"
          class="my-0 mx-1"
          type="date"
          aria-label="End Date"
          addon="start"
          data-cy="end-date"
        >
          <span slot="addon-start" class="input-group-text"> End </span>
        </input-box>
        <div class="btn-group">
          <icon-button
            id="detailsButton"
            additional-icon="x"
            type="primary"
            data-cy="date-range-reset"
            title="Reset"
            @click="resetDateRange"
          />
          <icon-button
            id="detailsButton"
            additional-icon="arrow-right"
            label="Submit"
            type="primary-dark"
            data-cy="date-range-submit"
            :disabled="!dateRange.changed"
            @click="refetchData"
          />
        </div>
        <alert
          v-if="dateRange.invalidMessage"
          class="my-0 mx-2 py-1"
          type="warning"
          :icon="true"
          :message="dateRange.invalidMessage"
        />
        <div v-else class="d-flex align-items-center ml-3">
          <div id="countBadge" class="badge badge-dark">
            {{ signedChecks.length }} rows
          </div>
        </div>
      </div>

      <div class="d-flex mb-2 mb-md-0">
        <input-box
          id="quickFilterBox__signedChecks"
          v-model="quickFilter"
          type="text"
          class="my-0"
          placeholder="Search..."
          addon="start"
        >
          <span slot="addon-start" class="input-group-text">
            <icon icon="search" />
          </span>
        </input-box>
        <icon-button icon="refresh-ccw" class="ml-1" @click="refetchData" />
        <div class="dropdown ml-1">
          <icon-button
            id="exportDropdown"
            label="Export"
            icon="download-cloud"
            class="dropdown-toggle"
            type="primary"
            align-right
            @click="exportMenuOpen = !exportMenuOpen"
          />
          <div
            class="dropdown-menu dropdown-menu-right"
            :class="{ show: exportMenuOpen }"
            aria-labelledby="exportDropdown"
          >
            <a
              id="buttonExportModal"
              class="dropdown-item d-flex flex-row align-items-center"
              type="button"
              @click="exportData"
            >
              <icon icon="file-x" fixed-width />
              <span class="ml-1">CSV</span>
            </a>
            <a
              id="printableVersion"
              class="dropdown-item d-flex flex-row align-items-center"
              type="button"
              @click="printData"
            >
              <icon icon="printer" fixed-width />
              <span class="ml-1">Print</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <advanced-search-bar
      v-if="gridApi"
      :grid-options="gridOptions"
      :show-selection-options="false"
      :filter-fields="filterFields"
    >
      <template slot="toolbar">
        <icon-button
          title="'Scroll Back to Top'"
          type="primary"
          outline
          class="mr-1"
          icon="arrow-up"
          @click="gridApi.ensureIndexVisible(0, 'top')"
        />
        <icon-button
          id="columnsFilterButton"
          label="Columns"
          type="primary"
          outline
          icon="columns"
          :active="showColumnPanel"
          @click="showColumnPanel = !showColumnPanel"
        />
      </template>
    </advanced-search-bar>

    <div class="flex-bigly-row grid-wrapper">
      <ag-grid-vue
        class="grid grid-flex ag-theme-balham"
        :row-data="signedChecks"
        :grid-options="gridOptions"
        @row-double-clicked="openDetailModal"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
      <columns-panel
        v-if="gridApi"
        v-show="showColumnPanel"
        class="panel"
        :column-api="columnApi"
      >
      </columns-panel>
    </div>

    <modal
      v-if="modalVisible.detail"
      id="detailsModal"
      title="View Check Details"
      size="custom"
      :max-width="800"
      role="dialog"
      @close="modalVisible.detail = false && onModalHidden"
    >
      <template #default>
        <div class="details-modal">
          <div class="details-modal__data">
            <p>
              <b class="mr-1">Payee Number:</b> {{ activeCheck.payee_number }}
            </p>
            <p>
              <b class="mr-1">Payee Name:</b>
              <span data-cy="payee-name">{{ activeCheck.payee_name }}</span>
            </p>
            <p>
              <b class="mr-1">Check Identifier:</b
              >{{ activeCheck.check_identifier | title }}
            </p>
            <p v-if="activeCheck.edoc_number !== null">
              <b class="mr-1">Edoc Number:</b> {{ activeCheck.edoc_number }}
            </p>
            <p>
              <b class="mr-1">Check Number:</b> {{ activeCheck.check_number }}
            </p>
            <p>
              <b class="mr-1">Due Date:</b>
              {{ formatDate(activeCheck.due_date) }}
            </p>
            <p>
              <b class="mr-1">Instructions:</b>
              {{ activeCheck.instructions | instructions }}
            </p>
            <p>
              <b class="mr-1">Contact Name:</b> {{ activeCheck.contact_name }}
            </p>
            <p>
              <b class="mr-1">Contact Number:</b>
              {{ activeCheck.contact_number }}
            </p>
            <p>
              <b class="mr-1"> Contact Email:</b>
              {{ activeCheck.contact_email }}
            </p>
            <p>
              <b class="mr-1">Contacted:</b>
              {{ activeCheck.contacted === true ? "Yes" : "No" }}
            </p>
            <p>
              <b class="mr-1">Picked Up:</b>
              {{ activeCheck.picked_up === true ? "Yes" : "No" }}
            </p>
            <img
              :src="activeCheck.signature.signature"
              class="img-fluid"
              alt="Signature image"
            />
            <p class="mb-0">
              {{ activeCheck.signature.last_name }},
              {{ activeCheck.signature.first_name }}
            </p>
            <small class="text-muted">{{ created }}</small>
            <hr class="my-2" />
            <!-- add archived signatures -->
            <div
              v-if="
                activeCheck.archived_signatures != null &&
                activeCheck.archived_signatures.length
              "
              class="col-12"
            >
              <archived-signatures
                :archived-signatures="activeCheck.archived_signatures"
              />
            </div>
            <i>{{ lastModifiedBy }}</i>
          </div>
          <comments
            class="details-modal__comments"
            :comments="activeCheck.comments"
          ></comments>
        </div>
      </template>
      <template #footer>
        <archive-button
          v-if="activeUser.canArchive"
          :open="archiveOpen"
          :archive="archive"
          :placeholder="activeCheck.payee_name"
          @input-reason="archive.form.reason = $event"
          @input-payee-name-confirm="archive.form.confirmPayeeName = $event"
          @archive-signature="archiveSignature"
          @close="archiveOpen = false"
          @open="archiveOpen = true"
        />
        <icon-button
          label="Close"
          type="primary"
          icon="x"
          @click="modalVisible.detail = false"
        />
      </template>
    </modal>

    <export-modal
      v-if="gridApi"
      :show="modalVisible.export"
      :grid-api="gridApi"
      @close="modalVisible.export = false"
    ></export-modal>

    <toast-group position="top right" width="350px"></toast-group>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import { mapActions, mapGetters, mapState } from "vuex"
import {
  add,
  isAfter,
  isEqual,
  differenceInYears,
  parseISO,
  formatISO,
  formatDistanceToNowStrict,
  isValid,
} from "date-fns"

import AdvancedSearchBar from "@/components/AdvancedSearchBar.vue"
import Comments from "@/components/Comments.vue"
import ColumnsPanel from "@/components/ColumnsPanel.vue"
import ArchivedSignatures from "@/components/ArchivedSignatures"
import ExportModal from "@/components/ExportModal.vue"
import ArchiveButton from "@/components/ArchiveButton.vue"
import ChecksService from "@/store/services/ChecksService"
import useGrid from "@/composables/useGrid"
import {
  InputBox,
  IconButton,
  Icon,
  Modal,
  Alert,
  ToastGroup,
} from "@/components/elements"
import { addDays } from "date-fns/esm"

export default {
  name: "SignedChecks",
  components: {
    AdvancedSearchBar,
    AgGridVue,
    Comments,
    ColumnsPanel,
    ExportModal,
    ArchivedSignatures,
    InputBox,
    IconButton,
    Icon,
    Modal,
    Alert,
    ToastGroup,
    ArchiveButton,
  },
  beforeRouteLeave(to, from, next) {
    this.clearCheck()
    this.clearErrors()
    this.clearCommentErrors()
    next()
  },
  setup(_props) {
    const gridConfig = {
      columnDefs: [
        {
          headerName: "SIGNED CHECK INFO",
          children: [
            { headerName: "Payee Name", field: "payee_name" },
            { headerName: "Payee #", field: "payee_number" },
            {
              headerName: "Check Identifier",
              field: "check_identifier",
              cellRenderer: "checkIdCellRenderer",
            },
            { headerName: "Check #", field: "check_number" },
            {
              headerName: "Instructions",
              field: "instructions",
              cellRenderer: "instructionsCellRenderer",
            },
            { headerName: "Edoc #", field: "edoc_number" },
            { headerName: "Org. Code", field: "org_code" },
          ],
        },
        {
          headerName: "CONTACT INFO",
          children: [
            { headerName: "Contact Name", field: "contact_name" },
            { headerName: "Contact Phone", field: "contact_number" },
            { headerName: "Contact Email", field: "contact_email" },
            {
              headerName: "Contacted?",
              field: "contacted",
              cellRenderer: "booleanCellRenderer",
            },
            {
              headerName: "Picked Up?",
              field: "picked_up",
              cellRenderer: "booleanCellRenderer",
            },
          ],
        },
        {
          headerName: "SIGNATURE INFO",
          children: [
            {
              headerName: "Received By",
              colId: "received_by",
              valueGetter: function (params) {
                return `${params.data.signature.first_name} ${params.data.signature.last_name}`
              },
            },
            {
              headerName: "Date/Time Received",
              field: "signature.created",
              colId: "date_received",
              cellRenderer: "dateCellRenderer",
            },
          ],
        },
        {
          headerName: "Date/Time Created",
          field: "created",
          hide: false,
          cellRenderer: "dateCellRenderer",
        },
        {
          headerName: "Created By",
          field: "user",
          hide: "true",
        },
      ],
    }
    const {
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnPanel,
      selectionExists,
      toggleMultiSelect,
      printGrid,
    } = useGrid(gridConfig) // repetitive but explicit about composition!
    return {
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnPanel,
      selectionExists,
      toggleMultiSelect,
      printGrid,
    }
  },
  data() {
    const endDate = new Date()
    const startDate = add(endDate, { months: -3 })
    return {
      exportMenuOpen: false,
      dateRange: {
        DEFAULT_START: startDate,
        DEFAULT_END: new Date(),
        changed: false,
        start: startDate,
        end: endDate,
        invalidMessage: null,
      },
      archiveOpen: false,
      modalVisible: {
        new: false,
        edit: false,
        detail: false,
        export: false,
      },
      archive: {
        form: {
          confirmPayeeName: "",
          reason: "",
        },
        errors: {},
      },
      filterFields: [
        "check_number",
        "check_identifier",
        "contact_email",
        "contact_name",
        "contact_number",
        "contacted",
        "created",
        "date_received",
        "edoc_number",
        "instructions",
        "org_code",
        "payee_name",
        "payee_number",
        "picked_up",
        "received_by",
        "user",
      ],
    }
  },
  computed: {
    ...mapGetters("checks", ["activeCheck", "signedChecks", "errors"]),
    ...mapGetters("comments", ["activeComment"]),
    ...mapState("checks", ["activeUser"]),

    lastModifiedBy() {
      if (!this.activeCheck.modified) return ""
      const date = new Date(this.activeCheck.modified)
      const relative = formatDistanceToNowStrict(date)
      return `Last modified by ${this.activeCheck.user} ${relative}`
    },

    created() {
      this.retrieveUserStatus()
      if (!this.activeCheck.signature.created) return ""
      const date = new Date(this.activeCheck.signature.created)
      return formatDistanceToNowStrict(date)
    },

    // Store dates as Date objects; return them as ISO strings
    startDate: {
      get() {
        return formatISO(this.dateRange.start, { representation: "date" })
      },
      set(value) {
        const date = parseISO(value, {})
        if (!isValid(date)) return
        if (!this.validateDateRange(date, this.dateRange.end)) return
        this.dateRange.changed = true
        this.dateRange.start = date
      },
    },

    endDate: {
      get() {
        return formatISO(this.dateRange.end, { representation: "date" })
      },
      set(value) {
        const date = parseISO(value)
        if (!isValid(date)) return
        if (!this.validateDateRange(this.dateRange.start, date)) return
        this.dateRange.changed = true
        this.dateRange.end = date
      },
    },

    endDatePlusOne() {
      const plusOne = addDays(this.dateRange.end, 1)
      return formatISO(plusOne, { representation: "date" })
    },
  },
  methods: {
    ...mapActions("checks", [
      "fetchChecks",
      "fetchCheckById",
      "setCheck",
      "setUser",
      "clearCheck",
      "setCheckById",
      "clearErrors",
    ]),
    ...mapActions("comments", {
      clearCommentErrors: "clearErrors",
      post: "post",
    }),
    async retrieveUserStatus() {
      const response = await ChecksService.userCanArchive()
      this.setUser(response.data)
    },
    async onGridReady({ api }) {
      api.showLoadingOverlay()
      const params = {
        startDate: this.startDate,
        endDate: this.endDatePlusOne, // because Django assumes start of day
      }
      await this.fetchChecks(params)
      api.hideOverlay()
    },

    async openDetailModal() {
      this.clearErrors()
      await this.fetchCheckById(this.gridApi.getSelectedRows()[0].id)
      this.activeComment.related_check = this.activeCheck.id
      this.modalVisible.detail = true
    },

    onModalHidden() {
      this.gridApi.redrawRows()
      this.activeComment.comment = ""
    },

    async refetchData() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDatePlusOne, // because Django assumes start of day
      }
      this.gridApi.showLoadingOverlay()
      await this.fetchChecks(params)
        .then(() => this.gridApi.redrawRows())
        .catch((error) => {
          this.$toast({
            type: "danger",
            title: "Whoops!",
            message: "Unable to fetch data, please try again.",
            duration: 3000,
          })
          console.error("something went wrong on Refresh:")
          console.error(error)
        })
      this.gridApi.hideOverlay()
      this.dateRange.changed = false
    },

    async archiveSignature() {
      // Reset the error warnings
      this.archive.errors = {}
      // Build the payload and make the api call
      if (this.activeCheck.payee_name === this.archive.form.confirmPayeeName) {
        let payload = {
          check_id: this.activeCheck.id,
          reason: this.archive.form.reason,
        }

        try {
          const response = await ChecksService.archiveSignature(payload)
          this.$toast({
            title: "Success!",
            type: "success",
            message: response.data,
            duration: 5000,
          })
          this.archiveOpen = false
          this.modalVisible.detail = false
          this.refetchData()
        } catch (error) {
          if (error.response.status == 400) {
            for (const err in error.response.data) {
              this.$set(this.archive.errors, err, error.response.data[err])
            }
          } else if (error.response.status == 500) {
            this.$toast({
              title: "Error",
              type: "error",
              message: error.response.data,
              duration: 5000,
            })
          }
        }
      } else {
        this.archive.errors["payee_name_confirm"] = "Payee name does not match!"
      }
    },

    instructionsRenderer(instr) {
      if (instr !== null) {
        if (instr === "ups") {
          return `UPS`
        } else if (instr === "fedex") {
          return `FedEx`
        } else {
          return `${instr.charAt(0).toUpperCase()}${instr.slice(1)}`
        }
      }
    },

    checkidRenderer(cid) {
      if (cid == "accounts payable") {
        return `Accounts Payable`
      } else if (cid == "payroll") {
        return `Payroll`
      } else {
        return cid
      }
    },

    formatDate(date) {
      if (date !== null) {
        return formatISO(new Date(date), { representation: "date" })
      }
    },

    archivedCreated(tempData) {
      return formatDistanceToNowStrict(new Date(tempData))
    },

    validateDateRange(start, end) {
      if (!isEqual(end, start) && isAfter(start, end)) {
        this.dateRange.invalidMessage = "Start Date must be before End Date"
        return false
      } else if (differenceInYears(end, start) > 5) {
        this.dateRange.invalidMessage = "Date range must be under 5 years"
        return false
      }
      this.dateRange.invalidMessage = null
      return true
    },

    resetDateRange() {
      this.dateRange.start = this.dateRange.DEFAULT_START
      this.dateRange.end = this.dateRange.DEFAULT_END
      this.refetchData()
    },

    exportData() {
      this.modalVisible.export = true
      this.exportMenuOpen = false
    },
    printData() {
      this.printGrid()
      this.exportMenuOpen = false
    },
  },
}
</script>

<style lang="postcss">
.details-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}

.archive-confirm {
  z-index: 1060;
  margin-right: 0px !important;

  .drop-target {
    height: 100% !important;
  }

  @media screen and (max-width: 600px) {
    .btn {
      i {
        margin-right: 0px !important;
      }

      .btn-label {
        display: none;
      }
    }
  }
}

@media print {
  @page {
    size: landscape;
  }
  body {
    zoom: 50%;
    height: auto;
  }
}
</style>
