<template>
  <div id="archivePopup" class="archive-confirm">
    <button
      v-if="!open"
      id="archiveButton"
      class="btn btn-danger h-100"
      style="display: inline-flex; align-items: center"
      data-cy="archive"
      @click="$emit('open')"
    >
      <icon icon="archive" class="mr-2" aria-hidden="true" />
      <div class="btn-label">Archive</div>
    </button>
    <div
      v-else
      class="card archive-dialog"
      style="margin-bottom: 0.125em; width: 400px"
    >
      <div class="card-block p-3">
        <h5 class="card-title">Archive Signature</h5>
        <alert
          class="w-100"
          type="warning"
          :icon="false"
          message="Are you sure you want to archive this signature? This action is irreversible."
        />
        <p class="w-100">
          The signature will not be deleted. Instead, it will be attached to the
          check as an "Archived Signature". The check will revert back to an
          un-signed state.
        </p>
        <input-box
          id="archiveName"
          label="Confirm Payee Name:"
          description="Confirm the payee name to proceed."
          :placeholder="placeholder"
          :danger="!!archive.errors.payee_name_confirm"
          :danger-message="archive.errors.payee_name_confirm"
          data-cy="archive-name"
          @input="$emit('input-payee-name-confirm', $event)"
        />
        <div
          class="form-group"
          :class="[{ 'has-danger': !!archive.errors.reason }]"
        >
          <label
            id="archiveReason-label"
            for="archiveReason"
            aria-label="Archive Reason"
          >
            Comment:
          </label>
          <div class="input-group">
            <textarea
              id="archiveReason"
              class="form-control"
              :class="[
                {
                  'form-control-danger': !!archive.errors.reason,
                },
              ]"
              aria-describedby="archiveReason-label"
              data-cy="archive-reason"
              @input="$emit('input-reason', $event.target.value)"
            />
          </div>
          <div v-if="!!archive.errors.reason" class="form-control-feedback">
            {{ archive.errors.reason }}
          </div>
        </div>
        <alert
          v-if="!!archive.errors.check_id"
          id="archiveCheckIdError"
          type="danger"
          :message="archive.errors.check_id"
        />
        <alert
          v-if="!!archive.errors.server"
          id="archiveServerError"
          type="danger"
          :message="archive.errors.server"
        />
        <div class="d-flex justify-content-end">
          <icon-button
            id="archiveCancel"
            label="Cancel"
            icon="x"
            type="secondary"
            data-cy="archive-cancel"
            @click="$emit('close')"
          />
          <icon-button
            id="archiveConfirm"
            class="ml-1"
            label="Archive"
            icon="archive"
            type="danger"
            data-cy="archive-confirm"
            @click="archiveSignature"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { InputBox, IconButton, Icon, Alert } from "@/components/elements"

export default {
  components: {
    InputBox,
    IconButton,
    Alert,
    Icon,
  },
  props: {
    archive: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    archiveSignature() {
      this.$emit("archive-signature")
    },
  },
}
</script>

<style lang="postcss" scoped>
div.archive-dialog {
  position: absolute;
  z-index: 2;
  right: 0.3rem;
  bottom: 0.3rem;
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  border-radius: 0.25rem;
}
</style>
